const colors = {
  teal: `#3b7d6b`,
  red: `#fe0033`,
  blue: `#7baab8`,
  black: `#000000`,
  orange: `#ffc083`,
  yellow: `#fecd8f`,
  white: `#ffffff`
}

export default colors
