import React from 'react'
import Colors from '../globals/colors'
import { css } from 'emotion'

const link = css({
  cursor: `pointer`,
  marginBottom: 0,
  transition: `color 0.3s ease`,
  '&:hover': {
    color: Colors.red
  }
})

const NotFoundPage = () => (
  <div className={css({
    color: Colors.red,
    height: `100vh`,
    display: `flex`,
    flexDirection: `column`,
    alignItems: `center`,
    justifyContent: `center`
  })}>
    <p className={css({ color: Colors.white, letterSpacing: `3px`, marginBottom: 0 })}>SYSTEMS ADMINISTRATION &amp; MAINTENANCE</p>
    <h1 className={css({ margin: `2rem 0` })}>404 NOT FOUND</h1>
    <a href='/' className={link}>return home</a>
  </div>
)

export default NotFoundPage
